import { AuthAxios, ContableAxios } from "./config";

export const getAccountingPlan = async (extraPath: "inventory/" | "voucher/" | "", headquarterCode: string, companyUrl: string, year: number) => {
  const res = await ContableAxios.get(`/accounting-plan/${extraPath}${headquarterCode}/${year}/${companyUrl}`);
  return res.data;
};

export const postAccount = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await ContableAxios.post(`/accounting-plan/insert/${year}/${headquarterCode}/${companyUrl}`, data);
  return res.data;
};

export const putAccount = async (accountId: string, data: object) => {
  const res = await ContableAxios.put(`/accounting-plan/${accountId}`, data);
  return res.data;
};

export const uploadAccounts = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await ContableAxios.post(`/accounting-plan/upload/${year}/${headquarterCode}/${companyUrl}`, data);
  return res.data;
};

export const uploadAuxAccounts = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await ContableAxios.post(`/aux-account/upload/${headquarterCode}/${year}/${companyUrl}`, data);
  return res.data;
};

export const uploadBudget = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await ContableAxios.post(`/budget/upload/${headquarterCode}/${year}/${companyUrl}`, data);
  return res.data;
};

export const uploadVoucher = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await ContableAxios.post(`/import/massive-vouchers/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data);
  return res.data;
};

export const uploadVoucherXML = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await ContableAxios.post(`/import/xml/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data);
  return res.data;
};

export const uploadRRHHTxt = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await ContableAxios.post(`/import/txt/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data);
  return res.data;
};

export const getWorkingPeriods = async (headquarterCode: string, companyUrl: string, year: number) => {
  const res = await AuthAxios.get(`/working-period/${companyUrl}/${headquarterCode}/${year}`);
  return res.data;
};

export const postWorkingPeriods = async (companyUrl: string, headquarterCode: string, year: number, data: object) => {
  const res = await AuthAxios.post(`/working-period/${companyUrl}/${headquarterCode}/${year}`, data);
  return res.data;
};

// BUDGET

export const getBudget = async (headquarterCode: string, year: number, companyUrl: string) => {
  const res = await ContableAxios.get(`/budget/list/${headquarterCode}/${year}/${companyUrl}`);
  return res.data;
};

export const postBudget = async (headquarterCode: string, year: number, companyUrl: string, data: object) => {
  const res = await ContableAxios.post(`/budget/list/${headquarterCode}/${year}/${companyUrl}`, data);
  return res.data;
};

export const putBudget = async (budgetId: string, data: object) => {
  const res = await ContableAxios.put(`/budget/list/${budgetId}`, data);
  return res.data;
};

// FILES

export const getAllFiles = async (dates: any, year: number, company: string) => {
  const res = await AuthAxios.post(`/getallfiles/${year}/${company}`, dates);
  return res.data;
};

export const getCompanyFiles = async (dates: any, year: number, companyUrl: string, headquarterCode: string) => {
  const res = await AuthAxios.post(`/companyFiles/${companyUrl}/${headquarterCode}/${year}`, dates);
  return res.data;
};

export const postFile = async (companyUrl: string, headquarterCode: string, data: object) => {
  const res = await AuthAxios.post(`/saveFiles/${companyUrl}/${headquarterCode}`, data);
  return res.data;
};

export const putFile = async (fileId: string, data: object) => {
  const res = await AuthAxios.put(`/file/${fileId}`, data);
  return res.data;
};

// ACCOUNT ASSISTANT

export const getAccountAssistant = async (companyUrl: string, headquarterCode: string, year: number) => {
  const res = await ContableAxios.get(`/aux-account/list/${headquarterCode}/${year}/${companyUrl}`);
  return res.data;
};

export const postAccountAssistant = async (companyUrl: string, headquarterCode: string, year: number, data: object) => {
  let res = await ContableAxios.post(`/aux-account/${headquarterCode}/${year}/${companyUrl}`, data);
  return res.data;
};

export const putAccountAssistant = async (accountAssistantId: string, data: object) => {
  let res = await ContableAxios.put(`/aux-account/${accountAssistantId}`, data);
  return res.data;
};

export const getAccountAssistantByAccount = async (data: object) => {
  const res = await ContableAxios.post(`/aux-account/account`, data);
  return res.data;
};

export const getTypeCoins = async () => {
  const res = await ContableAxios.get(`/monedas`);
  return res.data;
};

export const getExchangeRateByDate = async (data: object) => {
  const res = await AuthAxios.post(`/exchange-rate`, data);
  return res.data;
};
