export const API_CONTABLE = process.env.REACT_APP_API_CONTABLE;
export const API_INVENTARIO = process.env.REACT_APP_API_INVENTARIO;
export const API_CUENTAS = process.env.REACT_APP_API_CUENTAS;
// export const API_RECLAMOS = process.env.REACT_APP_API_RECLAMOS;
// export const API_REPORTES = process.env.REACT_APP_API_REPORTES;
export const API_SCRAPPER = process.env.REACT_APP_API_SCRAPPER;
// export const API_MESSAGES = process.env.REACT_APP_API_MESSAGES;
// export const API_WORKERS = process.env.REACT_APP_API_WORKERS;

// export const API_CONTABLE = "https://auth.system.napcontable.com/api";
// export const API_INVENTARIO = "https://inventario.system.napcontable.com/api";
// export const API_CUENTAS = "https://contabilidad.system.napcontable.com/api";
export const API_REPORTES = "https://reportes.system.napcontable.com/api";
export const API_MESSAGES = "https://mensajes.system.napcontable.com/";
export const API_WORKERS = "https://colaboradores.system.napcontable.com/api";

// export const API_INVENTARIO = "http://192.168.1.49:5010/api";
// export const API_CUENTAS = "http://192.168.1.49:5020/api";
// export const API_REPORTES = "http://192.168.1.49:5020/api";
export const API_RECLAMOS = "http://192.168.1.49:8080/api";
